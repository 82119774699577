import React, { useEffect, useRef, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../common/axios";
import useGet from "../../hooks/useGet";
import { getErrors, hasData, toastDefault } from "../../utils/handler.utils";
import Loader from "../Loader/Loader";
function EditPackage(props) {
  const history = useHistory();
  const packageId = props.match.params.id;

  const [loading, setLoading] = useState(null);
  const [autoDelivery, setAutoDelivery] = useState(false);
  const [data, loadingData] = useGet(`admin/topup-package/${packageId}`);
  const [products, loadingProducts] = useGet(`admin/topup-products`);

  const product_id = useRef(null);
  const searchProductId = useRef(null);
  const name = useRef(null);
  const sell_price = useRef(null);
  const buy_price = useRef(null);
  const tagRef = useRef(null);
  const in_stock = useRef(null);
  const mapPackage = useRef(null);
  const autoDeliveryRef = useRef(null);

  const autoDeliveryChange = (e) => {
    setAutoDelivery(autoDeliveryRef.current.checked);
  };

  const [productId, setProductId] = useState(5555);
  const [packages, loadingPackages] = useGet(
    `admin/topup-packages/${productId}`,
    "",
    productId
  );

  useEffect(() => {
    if (data) {
      setAutoDelivery(data.auto_delivery === 1);
    }
  }, [loadingData]);

  const editPackageHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(`/admin/topup-package/update/${packageId}`, {
        product_id: product_id.current.value,
        name: name.current.value,
        price: sell_price.current.value,
        buy_price: buy_price.current.value,
        tag: tagRef.current.value,
        map_voucher_package: mapPackage?.current?.value,
        auto_delivery: autoDeliveryRef.current.checked ? 1 : 0,
        in_stock: in_stock.current.checked ? 1 : 0,
      })
      .then((res) => {
        toast.success("Topup package updated successfully", toastDefault);

        setTimeout(() => {
          history.push("/topup-packages");
        }, 1500);
      })
      .catch((err) => {
        toast.error(getErrors(err, false, true), toastDefault);
        setLoading(false);
      });
  };

  return (
    <section className="relative container_admin">
      <div className="bg-white overflow-hidden rounded">
        <div className="px-6 py-3 border-b border-gray-200">
          <h3 className="text-lg font-bold text-black">
            Edit topup package -- {data?.name}
          </h3>
        </div>
        <div className="py-10 px-4">
          <div className="w-full md:w-[70%] min-h-[250px] mx-auto py-6 relative border border-gray-200 px-4">
            {loadingData || loading || loadingProducts ? (
              <Loader absolute />
            ) : (
              ""
            )}
            {hasData(data) && hasData(products) && (
              <form onSubmit={editPackageHandler}>
                <div>
                  <div className="form_grid">
                    <div>
                      <label htmlFor="name">Product</label>
                      <select
                        defaultValue={data?.product_id}
                        ref={product_id}
                        className="form_input"
                      >
                        {products?.map((product, i) => (
                          <option key={i} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label htmlFor="name">Name</label>
                      <input
                        ref={name}
                        id="name"
                        defaultValue={data?.name}
                        className="form_input"
                        type="text"
                        placeholder="Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="form_grid">
                    <div>
                      <label htmlFor="sell_price">Sell price</label>
                      <input
                        ref={sell_price}
                        step="any"
                        id="sell_price"
                        defaultValue={data?.price}
                        className="form_input"
                        type="number"
                        placeholder="Sell price"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="buy_price">Buy price</label>
                      <input
                        ref={buy_price}
                        id="buy_price"
                        defaultValue={data?.buy_price}
                        className="form_input"
                        type="number"
                        placeholder="Buy price"
                        required
                      />
                    </div>
                  </div>

                  <div className="form_grid">
                    <div>
                      <label htmlFor="tag">Tag name</label>
                      <input
                        ref={tagRef}
                        defaultValue={data?.tag}
                        id="tag"
                        className="form_input"
                        type="text"
                        placeholder="Tag"
                      />
                    </div>
                  </div>

                  {autoDelivery && (
                    <>
                      <h1 className="text-xl">Auto delivery Section</h1>
                      Current Package ID: {data.map_voucher_package_id}
                      <div className="form_grid">
                        <div>
                          <label htmlFor="name">Product</label>
                          <select
                            ref={searchProductId}
                            onChange={(e) => setProductId(e.target.value)}
                            className="form_input"
                          >
                            <option value="">Select A Product</option>
                            {products?.map((product, i) => (
                              <option key={i} value={product.id}>
                                {product.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <label htmlFor="name">Package Select</label>
                          <select ref={mapPackage} className="form_input">
                            {loadingPackages && (
                              <option value="">Loading...</option>
                            )}
                            {!loadingPackages &&
                              packages?.map((pack, i) => (
                                <option key={i} value={pack.id}>
                                  {pack.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form_grid">
                    <div>
                      <label className="inline-flex items-center">
                        <input
                          ref={in_stock}
                          id="in_stock"
                          value="1"
                          className="form-checkbox"
                          type="checkbox"
                          defaultChecked={data?.in_stock == 1 ? true : false}
                        />
                        <span className="ml-2">In Stock</span>
                      </label>
                    </div>

                    <div>
                      <label className="inline-flex items-center">
                        <input
                          ref={autoDeliveryRef}
                          id="auto_delivery"
                          value="1"
                          onChange={autoDeliveryChange}
                          className="form-checkbox"
                          type="checkbox"
                          defaultChecked={
                            data?.auto_delivery == 1 ? true : false
                          }
                        />
                        <span className="ml-2">Auto delivery</span>
                      </label>
                    </div>
                  </div>

                  <div>
                    <button type="submit" className="cstm_btn w-full block">
                      Edit package
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(EditPackage);
